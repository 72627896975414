custom-input {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: none;
  outline: 0;
  margin-bottom: 15px;
  -webkit-appearance: none;
  @extend .theme-sub-paragraph;

  &:focus {
    border: 1px solid $dark-grey-bg;
  }
}

.input-dark {
  background-color: $light-grey-bg;
  color: $input-text-color;
  @extend custom-input;

  &:active,
  &:focus {
    color: $color-white;
  }
}
.input-grey {
  @extend .input-dark;
  background-color: $dark-grey-bg;
}

.input-light {
  background-color: $input-bg;
  color: $input-bg-light-text;
  @extend custom-input;

  &:active,
  &:focus {
    color: $dark-grey-bg;
  }
}

.form-dark {
  input,
  textarea,
  select {
    @extend .input-dark;
    background-color: $dark-grey-bg;
  }
  label {
    @extend .theme-sub-paragraph;
    color: $blue-grey;
    margin-bottom: 10px;
  }
}

.form-light {
  input,
  textarea,
  select {
    @extend .input-light;
  }
  label {
    @extend .theme-sub-paragraph;
    color: $dark-bg;
    margin-bottom: 10px;
  }
}

.search-input {
  border: 1px solid $light-grey-bg;
  background-color: $light-grey-bg;
  margin: 20px 0;
  border: 1px solid transparent;
  outline: 0;
  box-shadow: none;
  color: black;
}

.task-input {
  background-color: transparent;
  @extend .theme-heading-main;
  color: #fff;
  border: none;
  margin-bottom: 150px;
  outline: 0;
  box-shadow: 0;
  border: 1px solid transparent;
  width: 100%;
  font-size: 14px;
}


.cstm-checkbox{
  position: relative;

  label {
    width: calc(100% - 60px);
  }
  
  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    appearance: none;
    background-color: transparent;
    background-image: url(../../../public/images/icons/checkbox-outer.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0px 2px transparent;
    border: 1px solid transparent;
  }
  
  input[type="checkbox"]:checked {
      background-image: url(../../../public/images/icons/checked-tick.svg);
      
  }
}

input[type="file"] + label {
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  left: 30px;
  margin: 0;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  color: gray;
}

input[type="file"]::file-selector-button{
  visibility: hidden;
  padding-left: 35px;
}

.react-datetime-picker{
  width: 100%;

  &__wrapper{
    display: flex;
    flex-grow: 1;
    flex-shrink: unset;
    border: 0;
    align-items: center;
    background: $dark-grey-bg;
    border-radius: 5px; 
    padding-inline: 8px;
    margin-bottom: 15px;
  }

  &__inputGroup{
    min-width: auto;
    padding: 0;
    display: flex;
    align-items: center;
    flex: 1;
    gap: 2px;

    input{
      padding: 14px 0px;
      margin-bottom: 0; 
      text-align: center;
      border-radius: 0;
      background: transparent;
      width: min-content !important;
    }
  }
}

.css-yk16xz-control, .css-1pahdxg-control {
  background: $dark-grey-bg !important;
  border: 0 !important;
  box-shadow: none !important;
  margin-bottom: 15px !important;
  font-size: 12px !important;
  color: $input-text-color !important;

  .css-1hwfws3{
    padding: 9px 12px;
  }

  input{
    color: #fff !important;
    margin-bottom: 0 !important;
  }
}

.css-1uccc91-singleValue{
  color: $input-text-color !important;
}

.css-1wy0on6{
  display: none !important;
}

.css-26l3qy-menu{
    background: #000 !important ;
}

.css-1n7v3ny-option {
    background-color: #504f5a !important;
}