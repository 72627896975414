.right-side-bar {
  position: fixed;
  background-color: $light-grey-bg;
  color: white;
  width: 70vw;
  height: 100vh;
  top: 0;
  right: 0px;
  transform: translateX(100%);
  transition: 0.4s transform ease;
  z-index: 2;

  &.open {
    transform: translateX(0%);
    transition: 0.4s transform ease;
  }

  header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $dark-bg;

    img {
      border-right: 1px solid $dark-bg;
      padding: 20px;
    }
  }

  .body{
    font-size: 14px;

    p{
        margin-bottom: 6px;
        
        span{
            color: RGBA(255,255,255,45%);
        }
    }

    h3{
      color: $blue-grey;
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .dashboard-customize{
    .checkmark {
      width: 100%;
      color: white;
      padding: 10px;
      font-size: 14px;
      background: #4e4b56;
      border-radius: 5px;
      margin-bottom: 7px;
      @extend .cstm-checkbox;

      input[type="checkbox"]{
        background-position: calc(100% - 25px) 50% !important;
      }

      input[type="checkbox"]:checked{
        border: 1px solid RGBA(235 125 70 / 70%);
        box-shadow: 0 0px 2px $theme-color;
      }
    }
  }
  

  .task-feature {
    padding: 40px 30px;

    .tast-button {
      margin-top: 112px;
    }
  }
  &.courses-link {
    .sidebar-header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $dark-bg;
      cursor: pointer;
      .create {
        @extend .theme-text-white;
        @extend .main-tab-heading-sun;
        margin-bottom: 0;
        padding-left: 20px;
      }
      .back-click {
        border-right: 1px solid$dark-bg;
        padding: 23.5px;
      }
      .writing {
        margin-left: auto;
        padding-right: 20px;
      }
    }
    .task-feature {
      .safety {
        padding-top: 20px;
      }

      .cours-take {
        margin-bottom: 30px;
        color: #ffffffb3;
        @extend .theme-sub-paragraph;
      }
      .accordion-item {
        border-bottom: 1px solid $dark-bg;

        &:first-child {
          border-top: 1px solid $dark-bg;
        }

        button {
          width: 100%;
          background: transparent;
          text-align: left;
          color: #fff;
          border: 0;
          background-image: url(../../../public/images/icons/collapse.svg);
          background-repeat: no-repeat;
          padding: 10px 0;
          padding-left: 45px;
          background-position: 17px;
          background-size: 13px;
          @extend .theme-paragraph-courses;

          &.collapsed {
            color: #ffffffb3;
            background-image: url(../../../public/images/icons/expand.svg);
          }
        }

        .accordion-body {
          color: #ffffffb3;
          @extend .theme-sub-paragraph;
          padding-left: 45px;
          margin-bottom: 15px;
        }
      }
    }
  }
  /*add-invetory file start scss */
  .add-inventory {
    padding: 30px;

    .add-text {
      @extend .theme-text-white;
      @extend .theme-sub-paragraph;
    }
  }
  /*add-invetory file end scss */

  /*promotion-sidebar file start scss */
  .promotion-sidebar {
    padding: 30px;
    .upload {
      background-color: #302e38;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 5px;
      cursor: pointer;
      .upload-text {
        @extend .theme-sub-paragraph;
        color: $collapes-text-grey;
      }
    }
  }

  /*promotion-sidebar file start scss */
}
