$color-white: #ffffff;
$theme-color: #eb7c45;
$blue-grey: #a0aec0;
$light-grey-bg: #3e3b46;
$dark-grey-bg: #302e38;
$dark-bg: #16151a;
$input-text-color: #a0aec0b3;
$input-bg: #f0f0f0;
$input-bg-light-text: #898989;
$pareet-color: #30ba50;
$text-courses-white: #ffffff;
$collapes-text-grey: #ffffffb3;
$overlay-bg-color: #161513;
$product-item-color: #ffffff80;
$shoter-live: #8bc35b;
$gun-text-color: #8cdeb5;
$gun-text-color-2: #e5ab67;
$gun-border: #ffffff33;

card {
  background-color: $light-grey-bg;
  margin: 10px 0px;
  border-radius: 10px;
  overflow: hidden;

  /*
  p {
    font-size: 15px;
    font-family: "Inter-Regular";
    border-bottom: 2px solid $dark-grey-bg;
    padding: 14px;
    color: $color-white;
    white-space: nowrap;
  }

  h3 {
    font-size: 24px;
    font-family: "Inter-Bold";
    padding: 10px 0px 14px 15px;
    margin-bottom: 0px;
    color: $blue-grey;
  }
  */
}

.card {
  @extend card;
}

.itme-heading {
  font-size: 18px;
  color: $blue-grey;
  font-family: "Inter-Bold";
  border-bottom: 1px solid $dark-bg;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

.shotlist {
  h4 {
    font-size: 14px;
    color: $dark-bg;
    font-family: "Inter-Medium";
    padding: 9px 0px 0px 10px;
    margin-bottom: 0;
  }
  p {
    @extend h4;
    color: $blue-grey;
    padding: 2px 0px 10px 10px;
  }
}
