.login {
    display: flex;
    min-height: 100vh;

    .login_left-side,
    .login_right-side {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
    }

    .login_left-side{
        img{
            margin-top: auto;
        }
        p{
            margin-top: auto;
            color: $input-text-color;
        }
    }

    .login_right-side {
        background: $light-grey-bg;

        h2{
            margin-top: 50px;
            margin-bottom: auto;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 330px;
            margin-bottom: auto;

            input{
                margin-bottom: 0;
            }
        }
    }
}