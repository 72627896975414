.large-icon-buttons {
  display: flex;
  justify-content: center;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3e3b46;
    padding: 40px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #ffffff1f;
    cursor: pointer;

    &:hover{
      background: $dark-bg;
    }

    .text {
      .inventory {
        margin-bottom: 0;
        padding-left: 10px;

        color: $color-white;
      }
    }
  }
}

.inventories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .item {
    flex: 1 49%;

    >a {
      display: flex;
      gap: 15px;
      border: 1px solid $gun-border;
      border-radius: 10px;
      padding: 15px;

      &:hover{
        background: $dark-bg;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        background-color: #fff;
        object-fit: contain;
      }

      .status {
        @extend .main-tab-heading-sub;
        margin-bottom: 0;
        padding-top: 10px;
        text-align: center;

        &.rented {
          color: #8cdeb5;
        }

        &.work-order {
          color: #e5ab67;
        }
      }

      h3 {
        @extend .main-tab-heading-sun;
        color: $color-white;
      }

      p {
        font-size: 12px;
        font-family: "Inter-Medium";
        margin-bottom: 0;
        color: #ffffff80;

        &.price {
          align-self: flex-end;
          font-size: 14px;
          color: $theme-color;
          font-weight: bold;
          font-style: italic;
        }
      }

      .specifications {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.inventory__title-image{
  width: 140px;
  height: 140px;
  background: white;
  object-fit: contain;
  padding: 20px;
  border-radius: 5px;
}

.wrapper-body {
  .firearms-management {

    /* This is the firearms-menagement first file is start scss*/
    .detali-emp {
      .heading-emp {
        h3 {
          padding-left: 0px;
        }
      }
    }
  }

  .product-management-new {
    .gun-sampal {
      padding: 20px 28px;
    }

    .gun-sampal {
      .gun-academy {
        display: flex;
        // align-items: center;
        justify-content: center;
        border: 1px solid $gun-border;
        border-radius: 10px;
        padding: 15px 15px 10px;
        margin-bottom: 10px;

        .gun-price {
          .gun-shot-pro {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background-color: #fff;
          }

          .gun-heading {
            .gun-title {
              margin-bottom: 0;
              padding-top: 10px;
              color: $gun-text-color;
              @extend .main-tab-heading-sub;
              text-align: center;
            }

            .gun-color {
              color: $gun-text-color-2;
              word-break: break-all;
              white-space: nowrap;
            }
          }
        }

        .springfield {
          width: 100%;
          padding-left: 20px;

          .springfield-title {
            margin-bottom: 0;
            color: $color-white;
            @extend .main-tab-heading-sun;
          }

          .springfield-sub-title {

            // padding-top: 10px;
            .mod {
              margin-bottom: 0;
              color: $product-item-color;
              @extend .theme-label;
            }
          }

          .springfield-doller {
            padding-top: 10px;

            .doller-title {
              color: $theme-color;
              @extend .main-tab-semi-heading;
            }
          }
        }
      }
    }
  }
}