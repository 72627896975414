.side-bar {
  flex: 0 0 300px;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: auto;
  background-color: $dark-bg;
  padding-top: 20px;
  transition: 0.4s flex ease;

  ul {
    list-style: none;
    padding: 0;
    margin: 0px 20px;

    li {
      display: block;
      margin-bottom: 2px;

      &:hover {
        background-color: $dark-grey-bg;
        border-radius: 5px;
        color: $theme-color;
        cursor: pointer;
      }

      a {
        display: flex;
        text-decoration: none;
        padding: 10px 20px;
  
        &:hover {
          color: $theme-color;
        }

        img{
          margin-right: 15px;
        }
      }
    }

    .active {
      background-color: $dark-grey-bg;
      border-radius: 5px;
      color: $theme-color;
    }
  }
}

.side-bar-open {
  .side-bar {
    flex: 0;
    transform: translateX(-100%);
    transition: 0.4s all ease;
    overflow: hidden;
  }
}
