.courses{
  display: flex;
  gap: 20px;
  scroll-behavior: smooth;
  overflow-x: auto;

  &__list-item{
    flex-basis: 300px;
    flex-shrink: 0;
    background: white;
    color: #000;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 10px 3px rgba(0, 0, 0, 0);
    transition: .3s all ease;

    &:has( > a){
      &:hover{
        box-shadow: 0 10px 3px rgba(0, 0, 0);
        transition: .3s all ease;
      }
    }

    a{
      &:hover{
        color: $theme-color;
      }
    }

    h3{
      padding-bottom: 10px;
      font-size: 16px;
      border-bottom: 1px solid #E6E6E6;
    }

    p{
      display: flex;
      justify-content: space-between;
      color: rgb(132, 132, 132);
      font-size: 15px;
      margin-bottom: 0;
    }
  }
}

.events{

  &__title{
    color: $theme-color;
    font-size: 24px;
  }
  
  p{
    color: #ababab;
  }

  &__border-right{
    @media only screen and (min-width:992px) {
      border-right: 1px solid rgb(99, 99, 99) ;
    }
  }

  &__gallery{
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scroll-behavior: smooth;

    img{
      max-width: 100%;
      border: 1px solid #525255;
    }
  }

  ul{
    list-style: none;
    padding-left: 0;
    margin-top: 20px;

    li{

      h4{
        font-size: 18px;
      }
    }
  }
}