table {
  width: 100%;

  tr {
    color: $text-courses-white;

    &:hover {
      background: RGBA(22 21 26 / 45%);
    }

    th {
      padding: 10px;
      background-color: RGB(75 76 87);
      color: $blue-grey;
      white-space: nowrap;
      font-weight: inherit;
      font-size: 12px;
      font-family: "Inter-Regular";
    }

    &:last-child {
      td {
        border-bottom: none
      }
    }

    td {
      padding: 10px;
      border-bottom: 1px solid RGBA(255, 255, 255, 0.12);
      opacity: 1;
      font-size: 12px;
      font-family: "Inter-Regular";

      span.activity {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 10px;
        margin-right: 5px;

        &.online {
          background: green;
        }

        &.offline {
          background: grey;
        }

      }
    }
  }
}

.paggination {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;

  .btn {
    font-size: 16px;
    line-height: 30px;
    margin: 0;
    width: 30px;
    text-align: center;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 5px;

    li {
      a {
        display: block;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin: 0;
        background: #4b4c57;
        border-radius: 5px;

        &.active{
          background: #7a7b87;
        }
      }
    }
  }
}