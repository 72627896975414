@font-face {
  font-family: "Inter-Regular";
  src: url(../../../public/fonts/inter/Inter-Regular.ttf);
}
@font-face {
  font-family: "Inter-Bold";
  src: url(../../../public/fonts/inter/Inter-Bold.ttf);
}
@font-face {
  font-family: "Inter-Medium";
  src: url(../../../public/fonts/inter/Inter-Regular.ttf);
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url(../../../public/fonts/inter/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "Inter-Light";
  src: url(../../../public/fonts/inter/Inter-Light.ttf);
}

.main-tab-heading-1 {
  font-size: 24px;
  font-family: "Inter-Bold";
}

.main-tab-heading {
  font-size: 18px;
  font-family: "Inter-Bold";
}
.theme-heading-main {
  font-size: 18px;
  font-family: "Inter-Medium";
}
.main-tab-semi-heading-sub {
  font-size: 18px;
  font-family: "Inter-SemiBold";
  font-style: italic;
}
.theme-heading {
  font-size: 15px;
  font-family: "Inter-Regular";
}
h3, .theme-heading-sub {
  font-size: 15px;
  font-family: "Inter-Medium";
}

.main-tab-semi-heading {
  font-size: 14px;
  font-family: "Inter-Light";
  font-style: italic;
}
.main-tab-heading-sun {
  font-size: 14px;
  font-family: "Inter-Bold";
}
.theme-paragraph-courses {
  font-size: 14px;
  font-family: "Inter-Medium";
}
.theme-paragraph {
  font-size: 14px;
  font-family: "Inter-Regular";
}

.theme-sub-paragraph {
  font-size: 12px;
  font-family: "Inter-Regular";
}

.theme-label {
  color: $blue-grey;
  font-size: 12px;
  font-family: "Inter-Medium";
}

.main-tab-heading-sub {
  font-size: 11px;
  font-family: "Inter-Bold";
}

a {
  text-decoration: none;
  color: inherit;

  &:hover{
    text-decoration: none;
    color: $blue-grey;
  }
}
