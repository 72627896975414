.top-header {
    background-color: $dark-grey-bg;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $dark-bg;
    z-index: 1;

    > a{
        color: transparent;
    }

    img.icon {
        border-right: 1px solid #16151a;
        padding: 22px;
        width: 62px;
        height: 60px;
        cursor: pointer;
    }

    h3 {
        color: #ffffff;
        margin-bottom: 0;
        padding-left: 20px;
        @extend .main-tab-heading-sun;
    }

    ul {
        margin-left: auto;
        place-self: end;
        padding-right: 30px;
        cursor: pointer;

        li {
            list-style: none;
            display: inline-block;

            a {
                color: #fff;
                padding-left: 10px;
                user-select: none;
                -webkit-user-select: none;

                @extend .theme-paragraph;

                &:hover {
                    color: $blue-grey;
                }
            }
        }
    }

    .profile_badge{
        font-size: 13px;
        margin-bottom: 0;
        img{
            width: 35px;
            height: 35px;
            background: #3e3b46;
            padding: 10px;
            border-radius: 40px;
            margin-inline-end: 10px;
        }
    }
}