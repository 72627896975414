body {
  background-color: $dark-grey-bg;
  color: #fff;

  &.overlay {
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      height: 100vh;
      width: 100%;
      opacity: 0.75;
      z-index: 2;
      transition: .3s all ease-in-out;
    }
  }

  [class^=col-]{
    position: relative;
  }

  .wrapper {
    .wrapper-body {
      flex: 1;
      min-height: 100vh;
      background-color: $dark-grey-bg;
      transition: 0.4s flex ease-in-out;
      overflow: hidden;
    }
  }

  .cstm-loader {
    width: 22px;
    height: 22px;
    display: inline-block;
    border: 3px dashed;
    border-radius: 50px;
    animation: rotate 1.3s 0s infinite cubic-bezier(0.38, 0.41, 0.64, 0.5) normal;
    transform: rotate(0deg) scale(1.1);
    background: repeating-radial-gradient(#ea7c45 40px, transparent 74px);
  }

  @keyframes rotate {
    0%{
      transform: rotate(0turn) scale(1);
    }
    50%{
      transform: rotate(0.5turn) scale(0.9);
    }
    100%{
      transform: rotate(1turn ) scale(1);
    }
  }
}


.disabled{
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}