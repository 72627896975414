
  .discounts {

      .item {
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;

        img{
          width: 100%;
        }

        .detail {
          padding: 10px;
          h3 {
            color: $dark-bg;
            margin-bottom: 0;
            border-bottom: 1px solid $blue-grey;
          }

          p {
            color: $blue-grey;
            padding-top: 10px;
            margin-bottom: 0;
            font-weight: bold;
          }
        }
      }
  }
