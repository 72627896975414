.membership {
  .outer {
    background-color: $light-grey-bg;
    padding: 15px;
    padding-top: 0;

    .plans {
      transform: translateY(-50%);
    }
  }

  .indvidual {
    background-image: linear-gradient(180deg, #35333e, #302e38);
    border-radius: 5px;

    .ind-heading {
      @extend .theme-paragraph-courses;
      @extend .theme-text;
      margin-bottom: 0;
      padding: 10px;
      border-bottom: 1px solid $dark-bg;
    }

    .entry-fee {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 15px 10px 15px;

      .annual {
        .doller {
          margin-bottom: 0;

          @extend .main-tab-semi-heading-sub;
          @extend .theme-text-white;
        }

        .year {
          @extend .main-tab-heading-sub;
          @extend .theme-text-blue-grey;
          margin-bottom: 0;
        }
      }
    }

    .member-icon {
      padding: 0 15px 15px;

      .member-text {
        @extend .main-tab-heading-sub;
        @extend .theme-text-blue-grey;
      }
    }
  }

  .add-members {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed #646464;
    height: 100%;
    cursor: pointer;
    opacity: 100%;

    .add-member-text {
      margin-bottom: 0;
      @extend .theme-text-blue-grey;
      @extend .main-tab-heading-sun;
    }
  }

  .member-heding {
    margin-top: 26px;

    .ship-title {
      color: $product-item-color;
      @extend .main-tab-heading-sub;
      margin-bottom: 0;
    }
  }

  .management-add {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #646464;
    flex-direction: column;
    padding: 50px 0;
    margin-top: 20px;
    cursor: pointer;
    opacity: 100%;

    .managa-text {
      color: $product-item-color;
      @extend .main-tab-heading-sun;
    }
  }
}



.card {

  > h3 {
    color: $blue-grey;
    @extend .main-tab-heading-sun;
  }


    .table {
      th {
        @extend .theme-sub-paragraph;
        @extend .theme-text-white;
        border-bottom: 1px solid $dark-bg;
      }

      td {
        @extend .theme-sub-paragraph;
        @extend .theme-text-white;
        border-bottom: 1px solid $dark-bg;
      }
    }

    .members {
      max-height: 700px;
      overflow-y: auto;
  
      td {
        border-bottom: 1px solid $dark-bg;
    
        h6 {
          background-color: $light-grey-bg;
          border-radius: 50px;
          color: $product-item-color;
          font-size: 12px;
          display: table-cell;
          padding: 2px 10px;
        }
      }
  
    }
}