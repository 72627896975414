.theme-text {
  color: $theme-color !important;
}

.theme-text-blue-grey {
  color: $blue-grey;
}
.theme-courses-text {
  color: $text-courses-white;
}
.theme-text-dark {
  color: $dark-bg;
}
.theme-text-white {
  color: $color-white;
}

.theme-bg-dark {
  background-color: $dark-bg;
}

.theme-bg-dark-grey {
  background-color: $dark-grey-bg;
}

.theme-bg-light {
  background-color: $input-bg;
}
.theme-light-grey-bg {
  background-color: $light-grey-bg;
}
