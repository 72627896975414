.signin {
    background: $dark-grey-bg url('../../../../public/images/signin_bg.png') no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
    min-height: 100vh;
    z-index: 0;
    background-blend-mode: luminosity;

    // padding: 50px;
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background: RGBA(22 21 26 / 30%);
        mix-blend-mode: soft-light;
    }

    .outer {
        background-color: $color-white;
        padding: 60px;
        border-radius: 5px;

        h3::after {
            content: '';
            border: 1px solid $blue-grey;
            display: block;
            width: 90px;
            height: 2px;
            margin-top: 10px;
        }
    }

    

}


