custom-button {
  padding: 15px 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: none;
  @extend .theme-label;
  text-decoration: none;
  cursor: pointer;
}

.theme-button {
  background-color: $theme-color;
  color: $color-white;

  @extend custom-button;

  &:hover {
    background-color: $dark-bg;
    color: $color-white;
  }
}
.theme-button-add {
  background-color: $theme-color;
  color: $color-white;
  padding: 11px 40px;
  border-radius: 5px;
  border: 0;
  width: auto;
  margin: 13px;

  &:hover {
    background-color: $theme-color;
  }
}

.theme-button-1 {
  @extend custom-button;
  background-color: $theme-color;
  color: $color-white;
  &:hover {
    color: $color-white;
  }
}
.theme-disabled-button {
  background-color: $blue-grey;
  color: $light-grey-bg;
  @extend custom-button;

  &:hover {
    color: $light-grey-bg;
  }
}
