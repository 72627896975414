.categories {

  .item{
    border-bottom: 1px solid $light-grey-bg;
    padding: 20px 10px 0;

    &:hover{
      background-color: #373440;

      h3{
        color: white;
      }
    }

    .data{
      color: white;

      p{
        margin-bottom: 10px;
      }

      span{
        margin-right: 10px;
        color: RGBA(255 255 255/50%);
      }
    }
  }
}

.product-listing{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: flex-start;

  .card{
    flex: 1 0 300px;
    max-width: 400px;
    margin: 0;

    &:hover{
      background-color: $dark-bg;
    }

    > a{
      display: flex;
      padding: 15px;
    }

    img{
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 5px;
    }

    .detail{
      flex: 1;
      color: White;
      padding: 10px 20px 0;

      .qty{
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        p{
          margin-bottom: 0;
          color: $blue-grey;
          font-family: "Inter-Bold";
          font-size: 13px;

          span{
            display: block;
            color: white;
          }
        }
      }
    }

    &.live-product{
      position: relative;
      overflow: visible;

      &:before{
        content: "LIVE";
        position: absolute;
        right: 20px;
        top: -5px;
        background-color: $shoter-live;
        padding: 3px 20px;
        border-radius: 3px;
        @extend .theme-text-white;
        @extend .main-tab-heading-sub;
      }
    }
  }
}

.events__price-tab{
  display: flex;
  align-items: center;
  background: $dark-bg;
  border-radius: 10px;
  padding: 15px;

  p{
    flex: 1;
    margin-bottom: 0;
    padding: 0 5px;
    color: $theme-color !important;
    border-right: 1px solid $blue-grey;
    text-align: center;
    font-size: 18px;
    font-style: italic;
    font-weight: bolder;

    span{
      display: block;
      font-size: 13px;
      color: $blue-grey;
    }

    &:nth-last-of-type(1){
      border-right: 0px;
    }
  }
}



