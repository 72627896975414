.about-range{
  top: 100px;
}

.lane-diag {
  height: 100px;
  gap: 2px;
  margin-top: 15px;

  .item{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 10px;
    background: #242229;
    border-radius: 5px;

    &.reserved{
      background: linear-gradient(#242229, #ea7c45 100px);
    }
  }
}